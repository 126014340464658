import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { logo } from './img'


export default function Stores () {

    return (
        <div className="simple-bkgd">
            <Container fluid>
                <Row>
                    <Col lg={10} sm={12} xs={12}><div style={{textAlign:'center', marginTop:'0px', paddingTop:'0px'}}><img src={logo} alt="logo" width="30%" height="30%"/></div></Col>
                    {/* <Col xs={2}><div style={{textAlign:'right', paddingTop:'20px'}}><img src={login} alt="login" width="59" height="33"/></div></Col> */}
                    <Col lg={2} sm={0} xs={0}><div style={{textAlign:'right', paddingTop:'20px'}}></div></Col>
                </Row>
                <Row>
                    <Col lg={8} sm={12} xs={12}><div style={{fontSize: '1.6em', textAlign:'left', paddingTop:'2em', paddingLeft:'2em', height: '600px'}}>
                        <p>This page is reserved for <i>Stores development</i></p> 
                        <p>We are still building this Proof-Of-Concept page</p>
                        <p>Please check back and provide feedback for our Store Inventory Management system.</p>
                        <p>Thank you for visiting.</p> 
                        <br/><p><a href="/Landing">Home</a></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col><div><p style={{textAlign:'right', fontSize:'12px'}}>© 2025 CodingMachine, Inc. All Rights Reserved</p></div></Col>
                </Row>
            </Container>
        </div>
    )

}
 